import { useEffect, useState } from 'react'
import { ReactComponent as NoPodsImg } from '../../../images/no-pods.svg'
import IWTable, {
  Column,
  IWTableProps,
  SortDirection,
} from 'shared/components/thunderbolt/IWTable'
import IWPaginationControls from 'shared/components/thunderbolt/IWPaginationControls'
import { paginationOptions } from 'shared/types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UseLSTableWithPagination } from '../hooks/useLSTableWithPagination'
import { orderBy } from 'lodash'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { faSlidersSimple } from '@fortawesome/pro-regular-svg-icons'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
  StyledActions,
} from 'shared/components/thunderbolt/IWModal'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWToggleGroup from 'shared/components/thunderbolt/IWToggleGroup'
import IWToggle from 'shared/components/thunderbolt/IWToggle'

const PaginationWrapper = styled.div<{ hasSettings?: boolean }>`
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 0.5rem;
  justify-content: space-between;
  display: flex;
  flex-direction: ${(props) => !props.hasSettings && 'row-reverse'};
`

const StyledIWModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledModalToggleTextButtons = styled.span`
  display: flex;
  gap: 0.5rem;
`

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

type Props<T> = Omit<IWTableProps, 'data' | 'sortBy' | 'onSort'> & {
  items: T[]
  initialColumns?: LsColumnIsInView
  hasColumnSettings?: boolean
}

export type LsColumnIsInView = {
  [columnName: string]: Partial<Column> & {
    isInView: boolean
    disableToggle?: boolean
  }
}

export type LsColumnSelectorProps = {
  isOpen: boolean
  initialColumns: LsColumnIsInView
  onClose: () => void
  onConfirm: (columns: LsColumnIsInView) => void
}

export const EmptyMessage = () => {
  const { t } = useTranslation()
  return (
    <EmptyDiv data-testid="empty-message">
      <NoPodsImg />
      <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
        {t('loadScheduling.noSubmissionsDesc')}
      </IWTypography>
    </EmptyDiv>
  )
}

const ColumnSelector = ({
  isOpen,
  initialColumns,
  onClose,
  onConfirm,
}: LsColumnSelectorProps) => {
  const { t } = useTranslation()
  const [columns, setColumns] = useState(initialColumns)

  const handleToggleAll = (toggle: boolean) => {
    const currentColumns = Object.entries(columns).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          ...value,
          isInView: columns[key].disableToggle ? true : toggle,
          disableToggle: columns[key].disableToggle,
        },
      }),
      {} as LsColumnIsInView,
    )

    setColumns(currentColumns)
  }

  const handleColumnToggle = (columnName: string) => {
    setColumns({
      ...columns,
      [columnName]: {
        ...columns[columnName],
        isInView: !columns[columnName].isInView,
      },
    })
  }

  const handleClose = () => {
    setColumns(initialColumns)
    onClose()
  }

  useEffect(() => {
    setColumns(initialColumns)
  }, [initialColumns])

  return (
    <IWModal open={isOpen} onClose={handleClose}>
      <IWModalHeader>
        <IWTypography size="lg" weight="medium">
          {t('insightManager.rawDataTable.modal.manageColumns')}
        </IWTypography>
      </IWModalHeader>
      <StyledIWModalContent>
        <StyledModalToggleTextButtons>
          <IWButton
            variant="anchorMain"
            color="primary"
            onClick={() => handleToggleAll(false)}
          >
            {t('insightManager.rawDataTable.modal.toggleOff')}
          </IWButton>
          <span>/</span>
          <IWButton
            variant="anchorMain"
            color="primary"
            onClick={() => handleToggleAll(true)}
          >
            {t('insightManager.rawDataTable.modal.toggleOn')}
          </IWButton>
        </StyledModalToggleTextButtons>

        <IWToggleGroup>
          {Object.entries(columns).map((e) => {
            const [columnName, columnMetadata] = e
            return (
              <IWToggle
                key={columnName}
                id={columnName}
                label={columnMetadata.title}
                name={columnName}
                checked={columnMetadata.isInView}
                disabled={columnMetadata.disableToggle}
                onChange={() => handleColumnToggle(columnName)}
              />
            )
          })}
        </IWToggleGroup>
      </StyledIWModalContent>
      <IWModalFooter>
        <StyledActions>
          <IWButton onClick={handleClose} variant="outline" color="grey">
            {t('button.cancel')}
          </IWButton>
          <IWButton
            onClick={() => onConfirm(columns)}
            variant="main"
            color="primary"
          >
            {t('button.confirm')}
          </IWButton>
        </StyledActions>
      </IWModalFooter>
    </IWModal>
  )
}

function LoadSchedulingTable<T>({
  items,
  columns,
  isLoading = false,
  hasColumnSettings = false,
  pinnedColumns = {},
  initialColumns = {},
}: Props<T>) {
  const { t } = useTranslation()
  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false)
  const [cols, setCols] = useState(columns)

  const {
    pageNumber,
    itemsPerPage,
    itemsInView,
    setTablePageNumber,
    setTableItemsPerPage,
    setItems,
  } = UseLSTableWithPagination<T>(items)

  const [sort, setSort] = useState<
    | {
        dimension: string
        direction: SortDirection
      }
    | undefined
  >()

  const filterColumns = (inView: LsColumnIsInView) => {
    const tableColumns = Object.entries(inView)
      .filter((col) => col[1].isInView)
      .map((col) => ({
        ...col[1],
      }))

    setCols(tableColumns as Column[])
  }

  const handleSettingsOpen = () => {
    setIsColumnMenuOpen(true)
  }

  const handleSettingsClose = () => {
    setIsColumnMenuOpen(false)
  }

  const handleColumnSettingsConfirm = (inView: LsColumnIsInView) => {
    filterColumns(inView)
    setIsColumnMenuOpen(false)
  }

  useEffect(() => {
    if (Object.keys(initialColumns).length > 0) {
      filterColumns(initialColumns)
    }
    setItems(items)
  }, [items])

  useEffect(() => {
    setCols(columns)
  }, [columns])

  return (
    <>
      <div
        style={{
          marginBottom: '-1.5rem',
        }}
      >
        <IWTable
          border
          columns={cols}
          data={itemsInView}
          isLoading={isLoading}
          pinnedColumns={pinnedColumns}
          sortedBy={sort ? { [sort.dimension]: sort.direction } : undefined}
          onSort={(dimension, direction) => {
            if (direction === null) {
              setSort(undefined)
            } else {
              const sortdirection = direction === 'ascending' ? 'asc' : 'desc'
              const sorted = orderBy(items, [dimension], [sortdirection])

              setItems(sorted)
              setSort({
                dimension,
                direction,
              })
            }
          }}
        />
      </div>
      <PaginationWrapper hasSettings={hasColumnSettings}>
        {hasColumnSettings && (
          <IWButtonGroup color="primary" variant="outline" hasSolidBackground>
            <IWButton
              icon={faSlidersSimple}
              iconPosition="leading"
              disabled={isLoading}
              onClick={handleSettingsOpen}
            >
              {t('button.view')}
            </IWButton>
          </IWButtonGroup>
        )}
        <IWPaginationControls
          perPageLabel={t('tables.rowsPerPage')}
          itemsPerPage={itemsPerPage}
          perPageOptions={paginationOptions}
          totalItems={items.length}
          currentPage={pageNumber}
          onChangePage={(num) => setTablePageNumber(num)}
          onChangeItemsPerPage={(num) => {
            setTableItemsPerPage(num)
            setTablePageNumber(1)
          }}
        />
      </PaginationWrapper>
      <ColumnSelector
        isOpen={isColumnMenuOpen}
        onClose={handleSettingsClose}
        onConfirm={handleColumnSettingsConfirm}
        initialColumns={initialColumns}
      />
    </>
  )
}

export default LoadSchedulingTable
